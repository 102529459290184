import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import ForbesLogo from '../../static/images/logo-forbes.png';
import FifteenYears from '../../static/images/15-anos.png';
import WayraLogo from '../../static/images/logo-wayra.png';
import ExameLogo from '../../static/images/logo-exame.png';
import LazyImage from '../atoms/LazyImage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '20px',
    gridArea: 'achievements',

    '& .MuiBox-root': {
      height: '180px',
      textAlign: 'center',
      backgroundColor: '#211E42',
      borderRadius: '16px',
      display: 'grid',
      alignItems: 'center',
      alignContent: 'center',
      padding: '0 30px',

      '& img': {
        marginBottom: '20px',
        placeSelf: 'center',
      },
    },

    '& .MuiTypography-root': {
      color: `${theme.palette.primary.light} !important`,
    },

    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: 'auto',
      flexDirection: 'column',
      rowGap: '30px',
    },
  },
}));

const Achievements = ({ className }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)}>
      <Box>
        {/* <img src={ForbesLogo} alt="Logo da Forbes" /> */}
        <LazyImage img={ForbesLogo} alt="Logo da Forbes" />
        <Typography variant="body1">
          Conube na lista das
          principais startups para
          acompanhar
        </Typography>
      </Box>
      <Box>
        {/* <img src={FifteenYears} alt="15 anos no mercado" /> */}
        <LazyImage img={FifteenYears} alt="15 anos no mercado" />
        <Typography variant="body1">
          Mais de 15 anos de
          experiência no mercado
          contábil em nível mundial
        </Typography>
      </Box>
      <Box>
        {/* <img src={WayraLogo} alt="Logo da Wayra" /> */}
        <LazyImage img={WayraLogo} alt="Logo da Wayra" />
        <Typography variant="body1">
          Empresa acelerada
          pela Wayra, no programa
          Telefônica Open Future
        </Typography>
      </Box>
      <Box>
        {/* <img src={ExameLogo} alt="Logo da Exame" /> */}
        <LazyImage img={ExameLogo} alt="Logo da Exame" />
        <Typography variant="body1">
          Destaque no portal Exame
          com o tema contabilidade 4.0
        </Typography>
      </Box>
    </Box>
  );
};

export default Achievements;
