import React, { useRef } from 'react';
import {
  Box, Typography, makeStyles, Hidden,
} from '@material-ui/core';
import NoteCard from './NoteCard';
import Achievements from './Achievements';
import AchievementsMobile from './AchievementsMobile';
import Spacer from '../atoms/Spacer';
import ContentWrap from '../atoms/ContentWrap';
import TeamPicture from '../../static/images/conube-team.png';
import LazyImage from '../atoms/LazyImage';

import useIntersector from '../atoms/UseIntersector';
// import BgImage from '../../static/images/background-pattern-conube-team.png'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginTop: '40px',
    borderRadius: '35px 35px 0 0',
    backgroundColor: theme.palette.primary.bgDark,
    backgroundImage: 'url(/images/background-pattern-conube-team.png)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    '& .MuiTypography-root': {
      color: theme.palette.secondary.contrastText,
    },

    '@media screen and (max-width: 900px)': {
      marginTop: '70px',
    },
  },
  content: {
    padding: '90px 0',
    display: 'grid',
    gridGap: '40px',
    gridTemplateColumns: '1fr 1fr 2fr',
    gridTemplateRows: '1fr 1fr',
    gridTemplateAreas: `
      "box1 image image"
      "box2 box3 text"
      "achievements achievements achievements"
    `,

    '@media screen and (max-width: 900px)': {
      padding: '25px 0',
      gridGap: '20px',
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: '"image" "box1" "box2" "box3"',
    },
  },
  textWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '20px',
    gridArea: 'text',

    '@media screen and (max-width: 900px)': {
      display: 'none',
    },
  },
  imageWrap: {
    position: 'relative',
    gridArea: 'image',

    '@media screen and (max-width: 900px)': {
      height: '210px',
      marginTop: '-90px',
    },
  },
  imageFrame: {
    display: 'flex',
    position: 'absolute',
    borderRadius: '16px',
    width: '100%',
    height: '190%',
    overflow: 'hidden',
    bottom: 0,

    '& img': {
      width: '100%',
      objectFit: 'cover',
      alignSelf: 'center',
    },

    '@media screen and (max-width: 1060px)': {
      height: '160%',
    },

    '@media screen and (max-width: 900px)': {
      position: 'relative',
      height: '100%',

      '& img': {
        position: 'relative',
      },
    },
  },
  achievements: {
    '@media screen and (max-width: 900px)': {
      display: 'none',
    },
  },
}));

const ConubeTeam = () => {
  const classes = useStyles();
  const reference = useRef();

  // repensar isso aqui
  const isIntersecting = useIntersector({ ref: reference });

  return (
    <Box className={classes.root} style={{ backgroundImage: isIntersecting ? 'url(/images/background-pattern-conube-team.png)' : '' }}>
      <div ref={reference} />
      <ContentWrap>
        <Box className={classes.content}>
          <NoteCard
            title="Contadores"
            text="Profissionais experientes cuidam da contabilidade e de todas as obrigações acessórias da sua empresa."
            gridArea="box1"
            minHeight="100px"
          />

          <Box className={classes.imageWrap}>
            <Box className={classes.imageFrame}>
              {/* <img src={TeamPicture} alt="Equipe da Conube" /> */}
              <LazyImage img={TeamPicture} alt="Equipe da Conube" />
            </Box>
          </Box>

          <NoteCard
            title="Analistas fiscais"
            text="Nossos especialistas realizam a apuração dos seus impostos e verificam a melhor alternativa de tributação."
            gridArea="box2"
          />

          <NoteCard
            dark
            title="Consultores"
            text="Orientação trabalhista, administração de folha de pagamento, consultoria e tudo que seu negócio precisa."
            gridArea="box3"
          />

          <Box className={classes.textWrap}>
            <Typography variant="h4" component="h2">
              Temos um time de
              {' '}
              <br />
              especialistas pronto
              {' '}
              <br />
              para te atender!
            </Typography>
            <Spacer size={20} />
            <Typography variant="body1">
              Uma equipe comprometida em acompanhar a jornada do seu negócio
              com a Conube e garantir o seu sucesso.
            </Typography>
          </Box>

          <Hidden smDown>
            <Achievements className={classes.achievements} />
          </Hidden>

        </Box>
      </ContentWrap>

    </Box>
  );
};

export default ConubeTeam;
