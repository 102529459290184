import React, { useEffect, useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import Slider from 'react-slick';
import ForbesLogo from '../../static/images/logo-forbes.png';
import FifteenYears from '../../static/images/15-anos.png';
import WayraLogo from '../../static/images/logo-wayra.png';
import ExameLogo from '../../static/images/logo-exame.png';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ContentWrap from '../atoms/ContentWrap';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.bgDark,
    paddingBottom: '50px',
    '& .MuiTypography-root': {
      color: `${theme.palette.primary.light} !important`,
    },

    '@media screen and (max-width: 900px)': {
      gridTemplateColumns: 'auto',
      flexDirection: 'column',
      rowGap: '30px',
    },

    '& .slick-slide > div': {
      margin: '0 5px',
    },
    '& .slick-list': {
      margin: '0 -5px',
    },
    '& .slick-dots li button:before': {
      marginTop: '8px',
      color: 'white',
    },
  },
  wrapperConquest: {
    height: '180px',
    textAlign: 'center',
    backgroundColor: '#211E42',
    borderRadius: '16px',
    display: 'flex !important',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      marginBottom: '20px',
      placeSelf: 'center',
    },
  },
}));

const AchievementsMobile = ({ className }) => {
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // centerPadding: '60px',
  };
  return (
    <Box className={classes.root}>
      <ContentWrap>
        <Slider {...settings}>
          <Box className={classes.wrapperConquest}>
            <img src={ForbesLogo} alt="Logo da Forbes" />
            <Typography variant="body1">
              Conube na lista das
              principais startups para
              acompanhar
            </Typography>
          </Box>
          <Box className={classes.wrapperConquest}>
            <img src={FifteenYears} alt="15 anos no mercado" />
            <Typography variant="body1">
              Mais de 15 anos de
              experiência no mercado
              contábil em nível mundial
            </Typography>
          </Box>
          <Box className={classes.wrapperConquest}>
            <img src={WayraLogo} alt="Logo da Wayra" />
            <Typography variant="body1">
              Empresa acelerada
              pela Wayra, no programa
              Telefônica Open Future
            </Typography>
          </Box>
          <Box className={classes.wrapperConquest}>
            <img src={ExameLogo} alt="Logo da Exame" />
            <Typography variant="body1">
              Destaque no portal Exame
              com o tema contabilidade 4.0
            </Typography>
          </Box>
        </Slider>
      </ContentWrap>
    </Box>
  );
};

export default AchievementsMobile;
