import React from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import ContentWrap from '../atoms/ContentWrap';
import ContentSpacer from '../molecules/ContentSpacer';
import ConubeTeam from '../molecules/ConubeTeam';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '115px 0 150px',

    '& .MuiTypography-h3': {
      paddingBottom: '15px',
    },
    '& .MuiTypography-h6': {
      color: theme.palette.midGrey.main,
    },

    '@media screen and (max-width: 900px)': {
      paddingBottom: '115px 0 50px',
    },
  },
  text: {
    textAlign: 'center',
    padding: '0 150px',

    '@media screen and (max-width: 900px)': {
      padding: '0 20px',
    },
  },
}));

const QuemSomos = () => {
  const classes = useStyles();

  return (
    <>
      <ContentWrap className={classes.root}>
        <Box className={classes.text}>
          <Typography variant="h3" component="h1">Quem somos</Typography>
          <Typography variant="h6" component="h2">
            Estamos revolucionando a maneira de fazer contabilidade e reduzindo
            a burocracia no dia a dia das empresas. Facilitamos a vida dos
            empreendedores para que eles tenham tempo e possam focar apenas no
            crescimento dos seus negócios. Fazemos isso através de uma
            plataforma online e totalmente intuitiva, construída a partir de
            feedbacks dos próprios clientes.
          </Typography>
        </Box>
      </ContentWrap>
      <ConubeTeam />
      <ContentSpacer />
    </>
  );
};

export default QuemSomos;
