import React, { useEffect } from 'react';
import Main from '../components/pages/Main';
import WhoWeAre from '../components/pages/QuemSomos';

const QuemSomos = () => {
  return (
    <Main page="quem-somos">
      <WhoWeAre />
    </Main>
  );  
}
export default QuemSomos;
