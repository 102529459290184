import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const NoteCard = ({ title, text, minHeight, gridArea }) => {
  const useStyles = makeStyles(() => ({
    card: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '16px',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      padding: '30px 20px',
      textAlign:  'center',
      flex: 1,
      background: '#563DA8',
      minHeight,
      gridArea,
    },
  }));

  const classes = useStyles();
  
  return (
    <Box className={classes.card}>
      <Typography variant="h5">
        <strong>{title}</strong>
      </Typography>
      {text && <Typography variant="body1">{text}</Typography>}
    </Box>
  );
};

NoteCard.defaultProps = {
  text: null,
  minHeight: 'auto',
  gridArea: 'auto',
};

NoteCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  minHeight: PropTypes.string,
  gridArea: PropTypes.string,
};

export default NoteCard;
